<template>
    <div id="wx_login_container"></div>
</template>

<script>
// 微信扫码登录
export default {
    name: "weixin-login",
    props: ['state'],
    methods: {
        doLogin: function () {
            console.log(location.origin)
            let redirectUrl = encodeURIComponent(`${location.origin}/#/loginForward`);
            // let url = "https://open.weixin.qq.com/connect/qrconnect?appid=" + window.wxConfig.appid + "&redirect_uri=" + redirectUrl + "&response_type=code&scope=SCOPE&state=STATE#wechat_redirect";

            //生成二维码，等待扫描
            new WxLogin({
                // eslint-disable-next-line camelcase
                self_redirect: false,
                id: "wx_login_container",
                appid: window.wxConfig.appid,
                scope: "snsapi_login",
                // eslint-disable-next-line camelcase
                redirect_uri: redirectUrl,
                state: this.state || "wx",
                style: "",
                href: `${location.origin}/js/wxLogin.css`
            });
        }
    },
    mounted() {
        this.doLogin()
    }
}
</script>

<style>
#wx_login_container iframe {
    width: 100%;
}
</style>