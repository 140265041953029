<template>
	<div class="inactiveBox">
		<h2>账号未激活</h2>
		<p>请使用微信扫描二维码绑定账户绑定后可使用微信扫码登录</p>
		<div>
			<weixin-login></weixin-login>
		</div>
	</div>
</template>

<script>
import WeixinLogin from "@/views/login/weixinLogin";

export default {
    //微信激活
    name: "wxActivate",
    components: {WeixinLogin}
}
</script>

<style scoped>
	h2,
	h3,
	h4 {
		width: 100%;
		text-align: center;
	}

	.inactiveBox {
		text-align: center;
		padding-bottom: 100px;
	}

	.inactiveBox p {
		padding: 0 15%;
		font-size: 14px;
		line-height: 18px;
		color: #333;
	}
</style>